html, body, #root {
  height: 100%;
}
html {
  box-sizing: border-box;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
  user-select: none;
}
